import { Formik } from "formik";
import React from "react";
import { useState } from "react";
import { Button, Card, Col, Form, Row, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { selectUser, setUser } from "../../features/authSlice";
import { updatePartnerDetails } from "../../Services/adminApi";

const SocialInfo = () => {
  const { user } = useSelector(selectUser);
  const dispatch = useDispatch();
  const notify = (message) => {
    toast(message);
  };
  const [isLoading, setIsLoading] = useState(false);
  const handleSocialData = async (values, setSubmitting) => {
    setIsLoading(true);
    const bodyFormData = new FormData();
    bodyFormData.set("id", user.login_id || user.id);
    bodyFormData.set("facebook_link", values.facebook_link);
    bodyFormData.set("twitter_link", values.twitter_link);
    bodyFormData.set("googleplus_link", values.googleplus_link);
    bodyFormData.set("linkedin_link", values.linkedin_link);
    bodyFormData.set("instagram_link", values.instagram_link);

    const res = await updatePartnerDetails(bodyFormData);
    if (res.status) {
      console.log(res.data);
      notify(res.message);
      dispatch(setUser(res.data[0]));
    } else {
      notify(res.message);
    }
    setSubmitting(false);
    setIsLoading(false);
  };

  return (
    <>
      <Row>
        <Col md={12}>
          <Card className="h-100" data-aos={"fade-up"} data-aos-delay={200}>
            <Card.Header>
              <Card.Title>Social Informations</Card.Title>
            </Card.Header>
            <Card.Body>
              <Formik
                enableReinitialize={true}
                initialValues={{
                  facebook_link: user?.facebook_link,
                  twitter_link: user?.twitter_link,
                  googleplus_link: user?.googleplus_link,
                  linkedin_link: user?.linkedin_link,
                  instagram_link: user?.instagram_link,
                }}
                onSubmit={(values, { setSubmitting, resetForm }) => {
                  handleSocialData(values, setSubmitting, resetForm);
                }}>
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                }) => (
                  <Form
                    noValidate
                    onSubmit={handleSubmit}
                    className="form-horizontal">
                    <Form.Group className="mb-3 row">
                      <Form.Label className="col-md-3 align-self-center mb-0">
                        Facebook
                      </Form.Label>
                      <Col md={6}>
                        <Form.Control
                          onChange={handleChange}
                          onBlur={handleBlur}
                          name="facebook_link"
                          value={values.facebook_link}
                          type="text"
                          placeholder="http://"
                        />
                        <small className="text-danger">
                          {errors.facebook_link &&
                            touched.facebook_link &&
                            errors.facebook_link}
                        </small>
                      </Col>
                    </Form.Group>
                    <Form.Group className="mb-3 row">
                      <Form.Label className="col-md-3 align-self-center mb-0">
                        Twitter
                      </Form.Label>
                      <Col md={6}>
                        <Form.Control
                          onChange={handleChange}
                          onBlur={handleBlur}
                          name="twitter_link"
                          value={values.twitter_link}
                          type="text"
                          placeholder="http://"
                        />
                        <small className="text-danger">
                          {errors.twitter_link &&
                            touched.twitter_link &&
                            errors.twitter_link}
                        </small>
                      </Col>
                    </Form.Group>
                    <Form.Group className="mb-3 row">
                      <Form.Label className="col-md-3 align-self-center mb-0">
                        Google Plus
                      </Form.Label>
                      <Col md={6}>
                        <Form.Control
                          onChange={handleChange}
                          onBlur={handleBlur}
                          name="googleplus_link"
                          value={values.googleplus_link}
                          type="text"
                          placeholder="http://"
                        />
                        <small className="text-danger">
                          {errors.googleplus_link &&
                            touched.googleplus_link &&
                            errors.googleplus_link}
                        </small>
                      </Col>
                    </Form.Group>
                    <Form.Group className="mb-3 row">
                      <Form.Label className="col-md-3 align-self-center mb-0">
                        LinkedIn
                      </Form.Label>
                      <Col md={6}>
                        <Form.Control
                          onChange={handleChange}
                          onBlur={handleBlur}
                          name="linkedin_link"
                          value={values.linkedin_link}
                          type="text"
                          placeholder="http://"
                        />
                        <small className="text-danger">
                          {errors.linkedin_link &&
                            touched.linkedin_link &&
                            errors.linkedin_link}
                        </small>
                      </Col>
                    </Form.Group>
                    <Form.Group className="mb-3 row">
                      <Form.Label className="col-md-3 align-self-center mb-0">
                        Instagram
                      </Form.Label>
                      <Col md={6}>
                        <Form.Control
                          onChange={handleChange}
                          onBlur={handleBlur}
                          name="instagram_link"
                          value={values.instagram_link}
                          type="text"
                          placeholder="http://"
                        />
                        <small className="text-danger">
                          {errors.instagram_link &&
                            touched.instagram_link &&
                            errors.instagram_link}
                        </small>
                      </Col>
                    </Form.Group>
                    <hr />
                    <Button
                      variant="primary"
                      type={"submit"}
                      className="ma-btn-primary"
                      style={{ width: "155px" }}>
                      {isLoading ? (
                        <Spinner animation="border" size="sm" variant="light" />
                      ) : (
                        "Save Changes"
                      )}
                    </Button>
                  </Form>
                )}
              </Formik>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default SocialInfo;
