import { Formik } from "formik";
import React from "react";
import { useState } from "react";
import { Button, Card, Col, Form, Row, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { selectUser, setUser } from "../../features/authSlice";
import { updatePartnerDetails } from "../../Services/adminApi";
import { addCompanySchema } from "../../utils/validationSchemas";

const CompanyInfo = () => {
  const notify = (message) => {
    toast(message);
  };
  const dispatch = useDispatch();
  const { user } = useSelector(selectUser);
  const [isLoading, setIsLoading] = useState(false);
  const handleCompanyData = async (values, setSubmitting) => {
    setIsLoading(true);
    const bodyFormData = new FormData();
    bodyFormData.set("id", user.login_id || user.id);
    bodyFormData.set("company_name", values.company_name);
    bodyFormData.set("company_profile", values.company_profile);
    bodyFormData.set("company_address", values.company_address);
    bodyFormData.set("company_phone_no", values.company_phone_no);
    bodyFormData.set("website_URL", values.website_URL);
    const res = await updatePartnerDetails(bodyFormData);
    if (res.status) {
      notify(res.message);
      dispatch(setUser(res.data[0]));
    } else {
      notify(res.message);
    }
    setSubmitting(false);
    setIsLoading(false);
  };

  return (
    <>
      <Row>
        <Col md={12}>
          <Card className="h-100" data-aos={"fade-up"} data-aos-delay={200}>
            <Card.Header>
              <Card.Title>Company Informations</Card.Title>
            </Card.Header>
            <Card.Body>
              <Formik
                validationSchema={addCompanySchema}
                enableReinitialize={true}
                initialValues={{
                  company_name: user?.company_name,
                  company_profile: user?.company_profile,
                  company_address: user?.company_address,
                  company_phone_no: user?.company_phone_no,
                  website_URL: user?.website_URL,
                }}
                onSubmit={(values, { setSubmitting, resetForm }) => {
                  handleCompanyData(values, setSubmitting, resetForm);
                }}>
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                }) => (
                  <Form
                    noValidate
                    onSubmit={handleSubmit}
                    className="form-horizontal">
                    <Form.Group className="mb-3 row">
                      <Form.Label className="col-md-3 align-self-center mb-0">
                        Company Name <span className="text-danger">*</span>{" "}
                      </Form.Label>
                      <Col md={6}>
                        <Form.Control
                          onChange={handleChange}
                          onBlur={handleBlur}
                          name="company_name"
                          value={values.company_name}
                          type="text"
                          placeholder="Enter Company Name"
                        />
                        <small className="text-danger">
                          {errors.company_name &&
                            touched.company_name &&
                            errors.company_name}
                        </small>
                      </Col>
                    </Form.Group>
                    <Form.Group className="mb-3 row">
                      <Form.Label className="col-md-3 align-self-center mb-0">
                        Company Profile <span className="text-danger">*</span>{" "}
                      </Form.Label>
                      <Col md={6}>
                        <Form.Control
                          onChange={handleChange}
                          onBlur={handleBlur}
                          name="company_profile"
                          value={values.company_profile}
                          type="text"
                          placeholder="Enter Company Profile"
                        />
                        <small className="text-danger">
                          {errors.company_profile &&
                            touched.company_profile &&
                            errors.company_profile}
                        </small>
                      </Col>
                    </Form.Group>
                    <Form.Group className="mb-3 row">
                      <Form.Label className="col-md-3 align-self-center mb-0">
                        Company Address <span className="text-danger">*</span>{" "}
                      </Form.Label>
                      <Col md={6}>
                        <Form.Control
                          onChange={handleChange}
                          onBlur={handleBlur}
                          name="company_address"
                          value={values.company_address}
                          type="text"
                          placeholder="Enter Company Address"
                        />
                        <small className="text-danger">
                          {errors.company_address &&
                            touched.company_address &&
                            errors.company_address}
                        </small>
                      </Col>
                    </Form.Group>
                    <Form.Group className="mb-3 row">
                      <Form.Label className="col-md-3 align-self-center mb-0">
                        Company Phone No. <span className="text-danger">*</span>{" "}
                      </Form.Label>
                      <Col md={6}>
                        <Form.Control
                          onChange={handleChange}
                          onBlur={handleBlur}
                          name="company_phone_no"
                          value={values.company_phone_no}
                          type="text"
                          maxLength={10}
                          placeholder="Enter Phone No."
                        />
                        <small className="text-danger">
                          {errors.company_phone_no &&
                            touched.company_phone_no &&
                            errors.company_phone_no}
                        </small>
                      </Col>
                    </Form.Group>
                    <Form.Group className="mb-3 row">
                      <Form.Label className="col-md-3 align-self-center mb-0">
                        Website URL <span className="text-danger">*</span>{" "}
                      </Form.Label>
                      <Col md={6}>
                        <Form.Control
                          onChange={handleChange}
                          onBlur={handleBlur}
                          name="website_URL"
                          value={values.website_URL}
                          type="text"
                          placeholder="Enter Phone No."
                        />
                        <small className="text-danger">
                          {errors.website_URL &&
                            touched.website_URL &&
                            errors.website_URL}
                        </small>
                      </Col>
                    </Form.Group>
                    <hr />
                    <Button
                      variant="primary"
                      type={"submit"}
                      className="ma-btn-primary"
                      style={{ width: "155px" }}>
                      {isLoading ? (
                        <Spinner animation="border" size="sm" variant="light" />
                      ) : (
                        "Save Changes"
                      )}
                    </Button>
                  </Form>
                )}
              </Formik>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default CompanyInfo;
