import React, { useState } from "react";
import { Button, Card, Collapse, Dropdown } from "react-bootstrap";
import {
  BsArrowRight,
  BsBellSlashFill,
  BsBoxArrowRight,
  BsEnvelope,
  BsPersonLinesFill,
} from "react-icons/bs";
import _ from "lodash";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logout, selectUser } from "../features/authSlice";
import axios from "axios";
import { useEffect } from "react";

const Navbar = ({ toggle }) => {
  const { user } = useSelector(selectUser);
  const dispatch = useDispatch();

  const [allNotifications, setAllNotifications] = useState([]);
  const getNotificationsData = async () => {
    const res = await axios.get(
      `${process.env.REACT_APP_API_URL}/allNotifications`
    );
    if (res.status) {
      setAllNotifications(res.data);
    } else {
      setAllNotifications([]);
    }
  };
  const [allMessages, setAllMessages] = useState([]);
  const getMessagesData = async () => {
    const res = await axios.get(`${process.env.REACT_APP_API_URL}/allMessages`);
    if (res.status) {
      setAllMessages(res.data);
    } else {
      setAllMessages([]);
    }
  };

  const handleLogout = () => {
    dispatch(logout());
  };

  useEffect(() => {
    getNotificationsData();
    getMessagesData();
  }, []);

  const [navopen, setOpen] = useState(false);

  return (
    <>
      <div className="position-relative iq-banner">
        <nav className="nav navbar navbar-expand-lg navbar-light iq-navbar">
          <div className="container-fluid navbar-innr">
            <Link to="/" className="navbar-brand">
              <h4 className="logo-title">
                {/* <img
                  src="http://localhost/admin/assets/images/logo.png"
                  className="default-img"
                  width={150}
                  alt="Dunkez"
                /> */}
              </h4>
            </Link>
            <div className="sidebar-toggle" onClick={toggle}>
              <i className="icon">
                <BsArrowRight />
              </i>
            </div>
            <h5 className="fw-bold text-primary text-shadow-1">Partner 🎉</h5>
            <button
              className="navbar-toggler"
              onClick={() => setOpen(!navopen)}
              type="button">
              <span className="navbar-toggler-icon">
                <span className="mt-2 navbar-toggler-bar bar1" />
                <span className="navbar-toggler-bar bar2" />
                <span className="navbar-toggler-bar bar3" />
              </span>
            </button>
            <Collapse in={navopen} className="navbar-collapse">
              <ul className="mb-2 navbar-nav ms-auto justify-content-around justify-content-md-end flex-row gap-md-4 align-items-center navbar-list mb-lg-0">
                {/* ------------notifications */}
                <Dropdown>
                  <Dropdown.Toggle className="bg-white d-inline-flex p-0 border-0">
                    <div className="nav-link d-flex align-items-center">
                      <div className="btn-play">
                        <BsBellSlashFill className="bg-primary text-white p-1 fs-4 rounded-circle position-relative" />
                      </div>
                    </div>
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item className="sub-drop p-0">
                      <Card className="m-0 shadow">
                        <Card.Header className="py-3 d-flex justify-content-between bg-primary">
                          <div className="header-title">
                            <h5 className="mb-0 text-white">
                              All Notifications
                            </h5>
                          </div>
                        </Card.Header>
                        <Card.Body className="p-0">
                          {allNotifications.map((ele) => (
                            <Link to={ele.url} className="iq-sub-card">
                              <div className="d-flex align-items-center">
                                <img
                                  className="p-1 avatar-40 rounded-pill bg-soft-primary"
                                  src="/01.png"
                                  alt=""
                                />
                                <div className="ms-3">
                                  <div
                                    className="d-flex justify-content-between align-items-center"
                                    style={{ width: "100px" }}>
                                    <h6 className="mb-0 me-5">{ele.name}</h6>
                                    <small>{ele.time}</small>
                                  </div>
                                  <div>
                                    <div
                                      style={{
                                        fontSize: "13px",
                                        width: "100%",
                                        whiteSpace: "pre-line",
                                      }}>
                                      {ele.comment}....
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Link>
                          ))}
                          <Link to={"/notifications"}>
                            <Button className="w-100">View All</Button>
                          </Link>
                        </Card.Body>
                      </Card>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>

                {/* ---------messsage */}
                <Dropdown>
                  <Dropdown.Toggle className="bg-white d-inline-flex p-0 border-0">
                    <div className="nav-link d-flex align-items-center">
                      <div>
                        <BsEnvelope className="e p-1 fs-4 " />
                      </div>
                    </div>
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item className="sub-drop p-0">
                      <Card className="m-0 shadow">
                        <Card.Header className="py-3 d-flex justify-content-between bg-primary">
                          <div className="header-title">
                            <h5 className="mb-0 text-white">All Messages</h5>
                          </div>
                        </Card.Header>
                        <Card.Body className="p-0">
                          {allMessages.map((ele) => (
                            <Link to={ele.url} className="iq-sub-card">
                              <div className="d-flex align-items-center">
                                <img
                                  className="p-1 avatar-40 rounded-pill bg-soft-primary"
                                  src="/01.png"
                                  alt=""
                                />
                                <div className="ms-3">
                                  <div
                                    className="d-flex justify-content-between align-items-center"
                                    style={{ width: "100px" }}>
                                    <h6 className="mb-0 me-5">{ele.name}</h6>
                                    <small>{ele.time}</small>
                                  </div>
                                  <div>
                                    <div
                                      style={{
                                        fontSize: "13px",
                                        width: "100%",
                                        whiteSpace: "pre-line",
                                      }}>
                                      {ele.comment}....
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Link>
                          ))}
                          <Link to={"/messages"}>
                            <Button className="w-100">View All</Button>
                          </Link>
                        </Card.Body>
                      </Card>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>

                {/* ---------settings */}
                <Dropdown>
                  <Dropdown.Toggle className="bg-white d-inline-flex p-0 border-0">
                    <div className="nav-link d-flex align-items-center">
                      <h6 className="fw-medium">{user.user_name}</h6>
                    </div>
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item className="sub-drop w-100 p-0">
                      <Card className="m-0 shadow">
                        <Card.Header className="py-3 d-flex justify-content-between bg-primary">
                          <div className="header-title">
                            <h5 className="mb-0 text-white">
                              Profile Settings
                            </h5>
                          </div>
                        </Card.Header>
                        <Card.Body className="p-0">
                          <Link to="/settings" className="iq-sub-card">
                            <div className="d-flex align-items-center">
                              <BsPersonLinesFill className="fs-4" />
                              <div className="ms-3 w-100">
                                <h6 className="mb-0 ">Settings</h6>
                              </div>
                            </div>
                          </Link>
                          <a
                            href="#"
                            className="iq-sub-card"
                            onClick={handleLogout}>
                            <div className="d-flex align-items-center">
                              <BsBoxArrowRight className="fs-4" />
                              <div className="ms-3 w-100">
                                <h6 className="mb-0 ">Logout</h6>
                              </div>
                            </div>
                          </a>
                        </Card.Body>
                      </Card>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </ul>
            </Collapse>
          </div>
        </nav>
      </div>
    </>
  );
};

export default Navbar;
