import * as Yup from "yup";

const phoneRegExp = /^[6-9]{1}[0-9]{9}$/;

export const addClientSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Email is Required"),
  name: Yup.string().required("Name is Required"),
  phone: Yup.string()
    .matches(phoneRegExp, "contact number is not valid")
    .typeError("Phone no. must be a number")
    .min(10, "Enter minimum 10 character")
    .required("Phone no. is a Required"),
  password: Yup.string().required("Password is Required"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("Confirm Password is Required"),
  companyName: Yup.string().required("Company Name is Required"),
  companyProfile: Yup.string(),
  companyAddress: Yup.string().required("Company Address is Required"),
  companyPhone: Yup.string().required("Company Phone No. is Required"),
  domainName: Yup.string().required("Domain Name is Required"),
  companyLogo: Yup.string().required("Company Logo is Required"),
  facebookLink: Yup.string(),
  instgramLink: Yup.string(),
  twitterLink: Yup.string(),
  googleplus: Yup.string(),
  linkedin: Yup.string(),
});
export const addGeneralSchema = Yup.object().shape({
  user_email: Yup.string().email("Invalid email").required("Email is Required"),
  user_name: Yup.string().required("Name is Required"),
  user_phone: Yup.string()
    .matches(phoneRegExp, "contact number is not valid")
    .typeError("Phone no. must be a number")
    .min(10, "Enter minimum 10 character")
    .required("Phone no. is a Required"),
  company_logo: Yup.string(),
});
export const addCompanySchema = Yup.object().shape({
  company_name: Yup.string().required("Company Name is Required"),
  company_address: Yup.string().required("Company Address is Required"),
  company_phone_no: Yup.string().required("Company Phone No. is Required"),
  company_profile: Yup.string().required("Company Profile is Required"),
  website_URL: Yup.string().required("Website URL is Required"),
});

export const changePasswordSchema = Yup.object().shape({
  new_password: Yup.string()
    .min(8, "Password must be atleast 8 characters")
    .required("New Password is a Required "),
  re_type_password: Yup.string()
    .oneOf([Yup.ref("new_password"), null], "Passwords must match")
    .required("Confirm Password is a Required"),
});

export const oneClientSchema = Yup.object().shape({
  user_email: Yup.string().email("Invalid email").required("Email is Required"),
  user_name: Yup.string().required("Name is Required"),
  user_phone: Yup.string()
    .matches(phoneRegExp, "contact number is not valid")
    .typeError("Phone no. must be a number")
    .min(10, "Enter minimum 10 character")
    .required("Phone no. is a Required"),
  expire_date: Yup.string(),
  company_logo: Yup.string(),
});

export const oneClientPluginSchema = Yup.object().shape({
  background_image: Yup.string(),
  company_name: Yup.string().required("Business Name is Required"),
  company_profile: Yup.string(),
  company_address: Yup.string(),
  website_URL: Yup.string().required("Website URl is Required"),
  company_phone_no: Yup.string()
    .matches(phoneRegExp, "contact number is not valid")
    .typeError("Phone no. must be a number")
    .min(10, "Enter minimum 10 character"),
  plugin_appearance: Yup.string(),
});

export const forgotPasswordSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Email is Required"),
});

export const otpSchema = Yup.object().shape({
  otp: Yup.number()
    .required("otp is required")
    .min(6, "otp must be at least 6 characters"),
});
export const updatePasswordSchema = Yup.object().shape({
  new_password: Yup.string()
    .min(8, "Password must be at least 8 characters")
    .required("Password is Required"),
  confirm_password: Yup.string()
    .oneOf([Yup.ref("new_password"), null], "Passwords must match")
    .required("Confirm Password is Required"),
});
