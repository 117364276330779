import React from "react";
import Modal from "react-bootstrap/Modal";

const Modaljs = ({ open, close, children, title }) => {
  return (
    <>
      <Modal size="xl" scrollable={true} show={open} onHide={close} centered>
        <Modal.Header className="bg-soft-primary" onClick={close} closeButton>
          <Modal.Title className="text-primary">{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{children}</Modal.Body>
      </Modal>
    </>
  );
};

export default Modaljs;
