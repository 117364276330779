import React from 'react'
import { Col, Card, Container, Row } from 'react-bootstrap'

const TestingPage = () => {
    return (
        <>
            <Container fluid className="mt-n5">
                <Row>
                    <Col md={12}>
                        <Card data-aos={"fade-up"}>
                            <Card.Body className="py-2">Testing Page
                                <Row>
                                    <Col></Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default TestingPage