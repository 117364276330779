import React, { useState, useEffect } from "react";
import Chart from "react-apexcharts";
import {
  Col,
  Card,
  Container,
  Form,
  Button,
  Nav,
  Row,
  Tab,
  Spinner,
} from "react-bootstrap";
import { BsEyeFill, BsFileEarmarkText, BsTools, BsXLg } from "react-icons/bs";
import { useSelector } from "react-redux";
import GeneralInfo from "../../components/ClientInfo/GeneralInfo";
import PluginInfo from "../../components/ClientInfo/PluginInfo";
import { selectUser } from "../../features/authSlice";
import {
  changePassword,
  getOnePartnerClient,
  getScriptData,
} from "../../Services/adminApi";
import { useParams } from "react-router-dom";
import { Formik } from "formik";
import { changePasswordSchema } from "../../utils/validationSchemas";
import { toast } from "react-toastify";

const ManagePlugin = () => {
  const notify = (message) => {
    toast.success(message);
  };
  const [isLoading, setIsLoading] = useState(false);
  const { id } = useParams();
  const { user } = useSelector(selectUser);
  const [oneClientData, setOneClientData] = useState({});
  const [chartData3, setChartData3] = useState({
    series: [
      {
        name: "Net Profit",
        data: [44, 55, 57, 56, 61, 58, 63, 60, 66],
      },
      {
        name: "Revenue",
        data: [76, 85, 101, 98, 87, 105, 91, 114, 94],
      },
      {
        name: "Free Cash Flow",
        data: [35, 41, 36, 26, 45, 48, 52, 53, 41],
      },
    ],
    options: {
      chart: {
        type: "bar",
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "100%",
          endingShape: "rounded",
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"],
      },
      xaxis: {
        categories: [
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
        ],
      },
      fill: {
        opacity: 1,
        colors: ["#d94f66", "#a6f5da", "#00e396"],
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return "$ " + val + " thousands";
          },
        },
      },
    },
  });

  const [scriptData, setScriptData] = useState("");

  const linetab = [
    {
      id: 1,
      number: 5,
      text: "Total Visits",
      text2: "Total No. of Visitors",
      icon: (
        <>
          <BsEyeFill />
        </>
      ),
      delay: 100,
      class: "success",
    },
    {
      id: 2,
      number: 2,
      text: "Valid Pages",
      text2: "Total No. of valid pages",
      icon: (
        <>
          <BsFileEarmarkText />
        </>
      ),
      delay: 200,
      class: "warning",
    },
    {
      id: 3,
      number: "Unpaid",
      text: "Assign Days.",
      icon: (
        <>
          <BsXLg />
        </>
      ),
      delay: 300,
      class: "danger",
    },
  ];

  const tabs = [
    { id: 1, name: "General" },
    { id: 2, name: "Change Password" },
    { id: 3, name: "Plugin Info" },
    { id: 4, name: "Plugin Code" },
  ];

  const handleChangePassword = async (values) => {
    setIsLoading(true);
    const passwordData = {
      id: id,
      password: values.new_password,
    };
    const res = await changePassword(passwordData);
    if (res.status) {
      notify(res.message);
    } else {
      notify(res.message);
    }
    setIsLoading(false);
  };

  const getData = async () => {
    const aData = {
      partner_id: user.login_id || user.id,
      client_id: id,
    };
    const res = await getOnePartnerClient(aData);
    if (res) {
      setOneClientData(res.data[0]);
    } else {
      setOneClientData([]);
    }
  };

  const fetchScriptData = async () => {
    const aData = {
      id: id,
    };
    const res = await getScriptData(aData);
    if (res.status) {
      setScriptData(res.data);
    } else {
      setScriptData("");
    }
  };

  useEffect(() => {
    fetchScriptData();
    getData();
  }, []);

  return (
    <>
      <Container fluid className="mt-n5 mb-3">
        <Tab.Container id="Profile-tabs" defaultActiveKey="1">
          <Row>
            <Col md={12}>
              <Card data-aos={"fade-up"}>
                <Card.Header>
                  <div className="header-title w-100 align-items-center flex-wrap-reverse gap-md-0 gap-3 d-flex justify-content-between">
                    <h4 className="card-title">Monthly Visit</h4>
                    {/* <Form.Control
                      type="date"
                      className="w-auto ms-auto me-3"
                      id="search-data"
                      placeholder="Search"
                    /> */}
                  </div>
                </Card.Header>
                <Card.Body className="py-2">
                  Testing Page
                  <Chart
                    options={chartData3.options}
                    series={chartData3.series}
                    type="bar"
                    height={300}
                  />
                </Card.Body>
              </Card>
            </Col>
            <Col md={12}>
              <Row>
                {linetab.map((tabcount, ibx) => (
                  <Col key={ibx} md={4}>
                    <Card
                      className="h-75"
                      data-aos={"fade-up"}
                      data-aos-delay={tabcount.delay}>
                      <Card.Body>
                        <div className="d-flex justify-content-between align-items-center">
                          <div className="ani-icon">
                            <div
                              className={`p-2 d-flex fs-4 text-primary rounded-circle bg-soft-${tabcount.class}`}>
                              {tabcount.icon}
                            </div>
                          </div>
                          <div>
                            <h2
                              className={`counter fw-bold text-truncate text-${tabcount.class}`}>
                              {tabcount.number}
                            </h2>
                          </div>
                        </div>
                        <div className="mt-3">
                          <h6 className="mb-0">{tabcount.text}</h6>
                          <p className="mb-1">{tabcount.text2}</p>
                        </div>
                      </Card.Body>
                    </Card>
                  </Col>
                ))}
              </Row>
            </Col>
            <Col md={12}>
              <Card data-aos={"fade-up"}>
                <Card.Body className="py-2">
                  <div className="d-md-flex flex-wrap align-items-center justify-content-between">
                    <div className="d-flex flex-wrap gap-md-0 gap-4 align-items-center">
                      <div className="position-relative me-3 profile-logo profile-logo1">
                        <BsTools className="fs-2" />
                      </div>
                      <div className="d-flex flex-wrap align-items-center mb-3 mb-sm-0">
                        <h4 className="me-2 h4">Manage</h4>
                        <span> - Plugin Settings</span>
                      </div>
                    </div>
                    <Nav
                      variant="pills"
                      className="bg-soft-primary d-md-inline-flex d-block rounded-4 mb-0">
                      {tabs.map((tab, idx) => (
                        <Nav.Item key={idx}>
                          <Nav.Link
                            className="ma-btn-primary"
                            eventKey={tab.id}>
                            {tab.name}
                          </Nav.Link>
                        </Nav.Item>
                      ))}
                    </Nav>
                  </div>
                </Card.Body>
              </Card>
            </Col>
            <Col md={12}>
              <Tab.Content>
                <Tab.Pane eventKey={"1"}>
                  <GeneralInfo data={oneClientData} />
                </Tab.Pane>
                <Tab.Pane eventKey={"2"}>
                  <Row>
                    <Col md={12}>
                      <Card
                        className="h-100"
                        data-aos={"fade-up"}
                        data-aos-delay={200}>
                        <Card.Header>
                          <Card.Title>Change Password Informations</Card.Title>
                        </Card.Header>
                        <Card.Body>
                          <Formik
                            validationSchema={changePasswordSchema}
                            initialValues={{
                              new_password: "",
                              re_type_password: "",
                            }}
                            onSubmit={(
                              values,
                              { setSubmitting, resetForm }
                            ) => {
                              handleChangePassword(
                                values,
                                setSubmitting,
                                resetForm
                              );
                            }}>
                            {({
                              values,
                              errors,
                              touched,
                              handleChange,
                              handleBlur,
                              handleSubmit,
                            }) => (
                              <Form
                                noValidate
                                onSubmit={handleSubmit}
                                className="form-horizontal">
                                <Form.Group className="mb-3 row">
                                  <Form.Label className="col-md-3 align-self-center mb-0">
                                    New Password{" "}
                                    <span className="text-danger">*</span>{" "}
                                  </Form.Label>
                                  <Col md={6}>
                                    <Form.Control
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      name="new_password"
                                      value={values.new_password}
                                      type="password"
                                      placeholder="Enter New Password"
                                    />
                                    <small className="text-danger">
                                      {errors.new_password &&
                                        touched.new_password &&
                                        errors.new_password}
                                    </small>
                                  </Col>
                                </Form.Group>
                                <Form.Group className="mb-3 row">
                                  <Form.Label className="col-md-3 align-self-center mb-0">
                                    Re-Type New Password{" "}
                                    <span className="text-danger">*</span>{" "}
                                  </Form.Label>
                                  <Col md={6}>
                                    <Form.Control
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      name="re_type_password"
                                      value={values.re_type_password}
                                      type="password"
                                      placeholder="Enter Retype New Password"
                                    />
                                    <small className="text-danger">
                                      {errors.re_type_password &&
                                        touched.re_type_password &&
                                        errors.re_type_password}
                                    </small>
                                  </Col>
                                </Form.Group>
                                <hr />
                                <Button
                                  variant="primary"
                                  type="submit"
                                  className="ma-btn-primary"
                                  style={{ width: "155px" }}>
                                  {isLoading ? (
                                    <Spinner
                                      animation="border"
                                      variant="light"
                                      size="sm"
                                    />
                                  ) : (
                                    "Save Changes"
                                  )}
                                </Button>
                              </Form>
                            )}
                          </Formik>
                        </Card.Body>
                      </Card>
                    </Col>
                  </Row>
                </Tab.Pane>
                <Tab.Pane eventKey={"3"}>
                  <PluginInfo data={oneClientData} />
                </Tab.Pane>
                <Tab.Pane eventKey="4">
                  <Card>
                    <pre
                      className="text-black p-3 text-start"
                      style={{ whiteSpace: "pre-line" }}>
                      {scriptData}
                    </pre>
                    <hr />
                    <div className="mt-0 m-3">
                      <Button
                        style={{ width: "150px" }}
                        onClick={() => {
                          if (navigator.clipboard && window.isSecureContext) {
                            return navigator.clipboard
                              .writeText(scriptData)
                              .then(() => {
                                notify("Code Copied Successfuly!");
                              });
                          } else {
                            let textArea = document.createElement("textarea");
                            textArea.value = scriptData;
                            textArea.style.position = "fixed";
                            textArea.style.left = "-999999px";
                            textArea.style.top = "-999999px";
                            document.body.appendChild(textArea);
                            textArea.focus();
                            textArea.select();
                            return new Promise((res, rej) => {
                              document.execCommand("copy") ? res() : rej();
                              textArea.remove();
                              notify("Code Copied Successfuly!");
                            });
                          }
                        }}>
                        Copy Code
                      </Button>
                    </div>
                  </Card>
                </Tab.Pane>
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
      </Container>
    </>
  );
};

export default ManagePlugin;
