import { Formik } from "formik";
import React from "react";
import { useState } from "react";
import { Button, Card, Col, Form, Row, Spinner } from "react-bootstrap";
import { BiEdit } from "react-icons/bi";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { updateOneClient } from "../../Services/adminApi";
import { oneClientPluginSchema } from "../../utils/validationSchemas";

const PluginInfo = ({ data }) => {
  const { id } = useParams();
  const notify = (message) => {
    toast(message);
  };
  const [isLoading, setIsLoading] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const handleOneClientData = async (values, setSubmitting) => {
    setIsLoading(true);
    const bodyFormData = new FormData();
    bodyFormData.set("id", id);
    bodyFormData.set("update_type", "plugin_info");
    bodyFormData.set(
      "background_image",
      selectedImage ? selectedImage : values.background_image
    );
    bodyFormData.set("company_name", values.company_name);
    bodyFormData.set("company_profile", values.company_profile);
    bodyFormData.set("company_address", values.company_address);
    bodyFormData.set("company_phone_no", values.company_phone_no);
    bodyFormData.set("website_URL", values.website_URL);
    bodyFormData.set("plugin_appearance", values.plugin_appearance);

    const res = await updateOneClient(bodyFormData);
    console.log(res);
    if (res.status) {
      notify(res.message);
    } else {
      notify(res.message);
    }
    setSubmitting(false);
    setIsLoading(false);
  };

  return (
    <>
      <Row>
        <Col md={12}>
          <Card className="h-100" data-aos={"fade-up"} data-aos-delay={200}>
            <Card.Header>
              <Card.Title>Plugin Informations</Card.Title>
            </Card.Header>
            <Card.Body className="pt-0">
              <Formik
                validationSchema={oneClientPluginSchema}
                enableReinitialize={true}
                initialValues={{
                  background_image: data?.background_image
                    ? data?.background_image
                    : "",
                  company_name: data?.company_name,
                  company_profile: data?.company_profile,
                  company_address: data?.company_address,
                  company_phone_no: data?.company_phone_no,
                  website_URL: data?.website_URL,
                  plugin_appearance: data?.plugin_appearance,
                }}
                onSubmit={(values, { setSubmitting, resetForm }) => {
                  handleOneClientData(values, setSubmitting, resetForm);
                }}>
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                }) => (
                  <Form
                    noValidate
                    onSubmit={handleSubmit}
                    className="form-horizontal">
                    <Form.Group className="mb-3 row">
                      <Form.Label className="col-md-3 align-self-center mb-0">
                        Background Image
                      </Form.Label>
                      <Col md={6}>
                        <div className="d-flex">
                          <img
                            src={
                              selectedImage
                                ? URL.createObjectURL(selectedImage)
                                : data?.background_image
                            }
                            width={"100px"}
                          />
                          <BiEdit
                            className="fs-4 position-relative "
                            style={{ left: "20px" }}
                          />
                          <Form.Control
                            onChange={(e) => {
                              handleChange(e);
                              setSelectedImage(e.target.files[0]);
                            }}
                            onBlur={handleBlur}
                            className="position-relative opacity-0 "
                            name="background_image"
                            type="file"
                            style={{ width: "20px" }}
                          />

                          <small className="text-danger">
                            {errors.background_image &&
                              touched.background_image &&
                              errors.background_image}
                          </small>
                        </div>
                      </Col>
                    </Form.Group>
                    <Form.Group className="mb-3 row">
                      <Form.Label className="col-md-3 align-self-center mb-0">
                        Business Name <span className="text-danger">*</span>{" "}
                      </Form.Label>
                      <Col md={6}>
                        <Form.Control
                          onChange={handleChange}
                          onBlur={handleBlur}
                          name="company_name"
                          value={values.company_name}
                          type="text"
                          placeholder="Enter Business Name"
                        />
                        <small className="text-danger">
                          {errors.company_name &&
                            touched.company_name &&
                            errors.company_name}
                        </small>
                      </Col>
                    </Form.Group>
                    <Form.Group className="mb-3 row">
                      <Form.Label className="col-md-3 align-self-center mb-0">
                        Business Description{" "}
                        <span className="text-danger">*</span>{" "}
                      </Form.Label>
                      <Col md={6}>
                        <Form.Control
                          onChange={handleChange}
                          onBlur={handleBlur}
                          name="company_profile"
                          value={values.company_profile}
                          type="text"
                          placeholder="Enter Business Description"
                        />
                        <small className="text-danger">
                          {errors.company_profile &&
                            touched.company_profile &&
                            errors.company_profile}
                        </small>
                      </Col>
                    </Form.Group>
                    <Form.Group className="mb-3 row">
                      <Form.Label className="col-md-3 align-self-center mb-0">
                        Business Address <span className="text-danger">*</span>{" "}
                      </Form.Label>
                      <Col md={6}>
                        <Form.Control
                          onChange={handleChange}
                          onBlur={handleBlur}
                          name="company_address"
                          value={values.company_address}
                          type="text"
                          placeholder="Enter Business Address"
                        />
                        <small className="text-danger">
                          {errors.company_address &&
                            touched.company_address &&
                            errors.company_address}
                        </small>
                      </Col>
                    </Form.Group>
                    <Form.Group className="mb-3 row">
                      <Form.Label className="col-md-3 align-self-center mb-0">
                        Phone No. <span className="text-danger">*</span>{" "}
                      </Form.Label>
                      <Col md={6}>
                        <Form.Control
                          onChange={handleChange}
                          onBlur={handleBlur}
                          name="company_phone_no"
                          value={values.company_phone_no}
                          type="text"
                          maxLength={10}
                          placeholder="Enter Phone No."
                        />
                        <small className="text-danger">
                          {errors.company_phone_no &&
                            touched.company_phone_no &&
                            errors.company_phone_no}
                        </small>
                      </Col>
                    </Form.Group>
                    <Form.Group className="mb-3 row">
                      <Form.Label className="col-md-3 align-self-center mb-0">
                        Website URL <span className="text-danger">*</span>{" "}
                      </Form.Label>
                      <Col md={6}>
                        <Form.Control
                          onChange={handleChange}
                          onBlur={handleBlur}
                          name="website_URL"
                          value={values.website_URL}
                          type="text"
                          placeholder="http//:"
                        />
                        <small className="text-danger">
                          {errors.website_URL &&
                            touched.website_URL &&
                            errors.website_URL}
                        </small>
                      </Col>
                    </Form.Group>
                    <Form.Group className="mb-3 row">
                      <Form.Label className="col-md-3 align-self-center mb-0">
                        Plugin Appearance left
                      </Form.Label>
                      <Col md={6}>
                        <Form.Select
                          name="plugin_appearance"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.plugin_appearance}
                          aria-label="Default select example">
                          <option value="right">Right</option>
                          <option value="left">Left</option>
                          <option value="hide">Hide</option>
                        </Form.Select>
                        <small className="text-danger">
                          {errors.plugin_appearance &&
                            touched.plugin_appearance &&
                            errors.plugin_appearance}
                        </small>
                      </Col>
                    </Form.Group>

                    <hr />
                    <Button
                      variant="primary"
                      type={"submit"}
                      className="ma-btn-primary"
                      style={{ width: "155px" }}>
                      {isLoading ? (
                        <Spinner animation="border" size="sm" variant="light" />
                      ) : (
                        "Save Changes"
                      )}
                    </Button>
                  </Form>
                )}
              </Formik>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default PluginInfo;
