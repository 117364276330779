import { Formik } from "formik";
import React from "react";
import { useState } from "react";
import { Button, Card, Col, Form, Row, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { selectUser, setUser } from "../../features/authSlice";
import { updatePartnerDetails } from "../../Services/adminApi";

const EmailCredentials = () => {
  const notify = (message) => {
    toast(message);
  };
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const { user } = useSelector(selectUser);
  const handleCredentialsData = async (values, setSubmitting) => {
    setIsLoading(true);
    const bodyFormData = new FormData();
    bodyFormData.set("id", user.login_id || user.id);
    bodyFormData.set("v_password", values.v_password);
    bodyFormData.set("v_email", values.v_email);
    bodyFormData.set("v_port", values.v_port);
    bodyFormData.set("v_host", values.v_host);

    const res = await updatePartnerDetails(bodyFormData);
    if (res.status) {
      notify(res.message);
      dispatch(setUser(res.data[0]));
    } else {
      notify(res.message);
    }
    setSubmitting(false);
    setIsLoading(false);
  };
  return (
    <>
      <Row>
        <Col md={12}>
          <Card className="h-100" data-aos={"fade-up"} data-aos-delay={200}>
            <Card.Header>
              <Card.Title>Credentials Informations</Card.Title>
            </Card.Header>
            <Card.Body>
              <Formik
                enableReinitialize={true}
                initialValues={{
                  v_password: user?.v_password,
                  v_email: user?.v_email,
                  v_port: user?.v_port,
                  v_host: user?.v_host,
                }}
                onSubmit={(values, { setSubmitting, resetForm }) => {
                  handleCredentialsData(values, setSubmitting, resetForm);
                }}>
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                }) => (
                  <Form
                    noValidate
                    onSubmit={handleSubmit}
                    className="form-horizontal">
                    <Form.Group className="mb-3 row">
                      <Form.Label className="col-md-3 align-self-center mb-0">
                        Email
                      </Form.Label>
                      <Col md={6}>
                        <Form.Control
                          onChange={handleChange}
                          onBlur={handleBlur}
                          name="v_email"
                          value={values.v_email}
                          type="email"
                          placeholder="Enter Email"
                        />
                        <small className="text-danger">
                          {errors.v_email && touched.v_email && errors.v_email}
                        </small>
                      </Col>
                    </Form.Group>
                    <Form.Group className="mb-3 row">
                      <Form.Label className="col-md-3 align-self-center mb-0">
                        Password
                      </Form.Label>
                      <Col md={6}>
                        <Form.Control
                          onChange={handleChange}
                          onBlur={handleBlur}
                          name="v_password"
                          value={values.v_password}
                          type="text"
                          placeholder="Enter Password"
                        />
                        <small className="text-danger">
                          {errors.v_password &&
                            touched.v_password &&
                            errors.v_password}
                        </small>
                      </Col>
                    </Form.Group>
                    <Form.Group className="mb-3 row">
                      <Form.Label className="col-md-3 align-self-center mb-0">
                        Port
                      </Form.Label>
                      <Col md={6}>
                        <Form.Control
                          onChange={handleChange}
                          onBlur={handleBlur}
                          name="v_port"
                          value={values.v_port}
                          type="text"
                          placeholder="http://Port"
                        />
                        <small className="text-danger">
                          {errors.v_port && touched.v_port && errors.v_port}
                        </small>
                      </Col>
                    </Form.Group>
                    <Form.Group className="mb-3 row">
                      <Form.Label className="col-md-3 align-self-center mb-0">
                        Host
                      </Form.Label>
                      <Col md={6}>
                        <Form.Control
                          onChange={handleChange}
                          onBlur={handleBlur}
                          name="v_host"
                          value={values.v_host}
                          type="text"
                          placeholder="http://Host"
                        />
                        <small className="text-danger">
                          {errors.v_host && touched.v_host && errors.v_host}
                        </small>
                      </Col>
                    </Form.Group>

                    <hr />
                    <Button
                      type="submit"
                      variant="primary"
                      className="ma-btn-primary"
                      style={{ width: "155px" }}>
                      {isLoading ? (
                        <Spinner animation="border" size="sm" variant="light" />
                      ) : (
                        "Save Changes"
                      )}
                    </Button>
                  </Form>
                )}
              </Formik>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default EmailCredentials;
