import React, { useState } from "react";
import {
	Button,
	Card,
	Carousel,
	Col,
	Container,
	Form,
	Row,
	Spinner,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from "yup";
import { login } from "../../features/authSlice";
import { useDispatch } from "react-redux";
import { loginApi } from "../../Services/authApi";
import { ToastContainer, toast } from "react-toastify";

const SignIn = () => {
	const notify = (message) => toast.error(message);
	const [isLoading, setIsLoading] = useState(false);
	const dispatch = useDispatch();
	const SignupSchema = Yup.object().shape({
		email: Yup.string().email("Invalid email").required("Required"),
		password: Yup.string().min(6, "Enter minimum 6 Digit").required("Required"),
	});

	const handleLogin = async (values, { setSubmitting }) => {
		// setIsLoading(true);
		const loginData = {
			email: values.email,
			password: values.password,
		};
		const res = await loginApi(loginData);
		if (res.status) {
			localStorage.setItem("token", res.token);
			dispatch(login(res.data));
		} else {
			notify(res.message);
			console.log(res.message);
		}
		setSubmitting(false);
		// setIsLoading(false);
	};
	const slide = [
		{
			id: 1,
			class: "first-img",
			bottom: "my-bottom",
			title: (
				<>
					<span className="text-primary">G</span>oogle Panda
				</>
			),
			text: (
				<>
					{" "}
					Google Panda is a major change to Google's search results ranking
					algorithm that was first released in February 2011. The change aimed
					to lower the rank of "low-quality sites" or "thin sites", in
					particular "content farms", and return higher-quality sites near the
					top of the search results.
				</>
			),
			img: "https://thewingshield.com/assets/img/panda.gif",
		},
		{
			id: 2,
			class: "first-img2",
			title: (
				<>
					<span className="text-primary">G</span>oogle Penguin
				</>
			),
			text: (
				<>
					Google Penguin was a codename for a Google algorithm update that was
					first announced on April 24, 2012. The Penguin Update works by
					crawling backlinks on websites and assigning each backlink a
					score.Today, the Penguin update is a part of the core algorithm of the
					Google search engine.
				</>
			),
			img: "https://thewingshield.com/assets/img/penguin.gif",
		},
		{
			id: 3,
			title: (
				<>
					<span className="text-primary">G</span>oogle Hummingbird
				</>
			),
			text: (
				<>
					Hummingbird is the codename given to a significant algorithm change in
					Google Search in 2013. Its name was derived from the speed and
					accuracy of the hummingbird. The change was announced on September 26,
					2013, having already been in use for a month. "Hummingbird" considers
					the context of the different words together, with the goal that pages
					matching the meaning do better, rather than pages matching just a few
					words
				</>
			),
			img: "https://giffiles.alphacoders.com/868/86811.gif",
		},
		{
			id: 4,
			title: (
				<>
					<span className="text-primary">G</span>oogle Pigeon
				</>
			),
			text: (
				<>
					Google Pigeon is the code name given to one of Google's local search
					algorithm updates. This update was released on July 24, 2014. Pigeon
					was designed to tie Google's local search algorithm closer to their
					web algorithm and to improve ranking parameters based on distance and
					location.
				</>
			),
			img: "https://thewingshield.com/assets/img/pigeon.gif",
		},
	];
	return (
		<div className="form-bg">
			<Container>
				<Row
					className="justify-content-center align-items-center min-vh-100"
					style={{ display: "-webkit-box" }}
				>
					<ToastContainer />
					<Col md={11}>
						<Row className="gx-0">
							<Col md={7}>
								<Card
									className="h-100 bg-transparent1 d-none d-md-block shadow-sm rounded-0 rounded-start text-white"
									data-aos={"fade-right"}
								>
									<Carousel controls={false} fade>
										{slide.map((slide, id) => (
											<Carousel.Item key={id} className="position-relative">
												<img
													className={slide.class}
													src={slide.img}
													alt="First slide"
												/>
												<Carousel.Caption
													className={`start-0 end-0 mx-2 text-white text-shadow-1 ${slide.bottom}`}
												>
													<h3 className="text-white mb-3 border-bottom d-inline-block pb-2">
														{slide.title}
													</h3>
													<p className="text-light">{slide.text}</p>
												</Carousel.Caption>
											</Carousel.Item>
										))}
									</Carousel>
								</Card>
							</Col>
							<Col md={5}>
								<Card
									className="h-100 px-md-3 rounded-0 flex-row-reverse align-items-center rounded-end"
									data-aos={"fade-left"}
								>
									<Card.Body>
										<h3 className="mb-3 text-shadow-1 fw-bold text-center">
											Login
										</h3>
										<Formik
											initialValues={{
												email: "rahulrawat@sartiaglobal.com",
												password: "12345678",
											}}
											validationSchema={SignupSchema}
											onSubmit={handleLogin}
										>
											{(props) => (
												<Form onSubmit={props.handleSubmit} className="myform">
													<Row>
														<Col>
															<Form.Group className="mb-3">
																<Form.Label>Email address</Form.Label>
																<Form.Control
																	type="email"
																	placeholder="Enter email"
																	name="email"
																	value={props.values.email}
																	onChange={props.handleChange}
																	onBlur={props.handleBlur}
																	isInvalid={Boolean(
																		props.touched.email && props.errors.email
																	)}
																/>
																{props.touched.email && props.errors.email && (
																	<Form.Control.Feedback type="invalid">
																		{props.errors.email}
																	</Form.Control.Feedback>
																)}
															</Form.Group>
														</Col>
													</Row>
													<Row>
														<Col>
															<Form.Group
																className="mb-3"
																controlId="formBasicEmail"
															>
																<Form.Label>Password</Form.Label>
																<Form.Control
																	type="password"
																	placeholder="Enter Password"
																	name="password"
																	value={props.values.password}
																	onChange={props.handleChange}
																	onBlur={props.handleBlur}
																	isInvalid={Boolean(
																		props.touched.password &&
																			props.errors.password
																	)}
																/>
																{props.touched.password &&
																	props.errors.password && (
																		<Form.Control.Feedback type="invalid">
																			{props.errors.password}
																		</Form.Control.Feedback>
																	)}
															</Form.Group>
														</Col>
													</Row>
													<Form.Group className="mb-3 text-end">
														<Link to="/ResetPassword">Forget Password</Link>
													</Form.Group>
													<Button
														type="submit"
														className="mb-3 text-uppercase w-100 btn-shadow"
														variant="primary"
													>
														{isLoading ? (
															<>
																<Spinner
																	animation="border"
																	variant="light"
																	size="sm"
																/>
															</>
														) : (
															"login"
														)}
													</Button>
												</Form>
											)}
										</Formik>
									</Card.Body>
								</Card>
							</Col>
						</Row>
					</Col>
				</Row>
			</Container>
		</div>
	);
};

export default SignIn;
