import React, { useState } from "react";
import { useEffect } from "react";
import { Button, Card, Col, Container, Form, Row } from "react-bootstrap";
import { BsPhoneVibrate, BsPlusCircle } from "react-icons/bs";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { FaUserCircle } from "react-icons/fa";
import { MdAlternateEmail } from "react-icons/md";
import { BiBuildings } from "react-icons/bi";
import { Si1Password } from "react-icons/si";
import { RiLinksLine } from "react-icons/ri";
import AddForm from "../../components/Forms/AddData";
import Modaljs from "../../components/Modals/Modal";
import { selectUser } from "../../features/authSlice";
import { getAllPartnersClients } from "../../Services/adminApi";

const AllPlugins = () => {
  const { user } = useSelector(selectUser);
  const [partnersData, setPartnersData] = useState([]);
  const [showTable, setshowTable] = useState(false);
  const handleCloseTable = () => setshowTable(false);
  const handleShowTable = () => setshowTable(true);
  const [filteredResults, setFilteredResults] = useState([]);
  const [searchInput, setSearchInput] = useState("");

  const searchItems = (searchValue) => {
    setSearchInput(searchValue);
    if (searchInput !== "") {
      const filteredData = partnersData.filter((item) => {
        return (
          Object.values(item.user_name)
            .join("")
            .toLowerCase()
            .includes(searchInput.toLowerCase()) ||
          Object.values(item.user_email)
            .join("")
            .toLowerCase()
            .includes(searchInput.toLowerCase()) ||
          Object.values(item.user_phone)
            .join("")
            .toLowerCase()
            .includes(searchInput.toLowerCase()) ||
          Object.values(item.website_URL)
            .join("")
            .toLowerCase()
            .includes(searchInput.toLowerCase())
        );
      });
      setFilteredResults(filteredData);
    } else {
      setFilteredResults(partnersData);
    }
  };

  const getData = async () => {
    const pData = {
      partner_id: user.login_id || user.id,
    };
    const res = await getAllPartnersClients(pData);
    if (res.status) {
      setPartnersData(res.data);
    } else {
      setPartnersData([]);
    }
  };
  useEffect(() => {
    getData();
  }, [showTable]);

  return (
    <>
      <Container fluid className="mt-n5">
        <Row>
          <Col md={12}>
            <Card data-aos={"fade-up"}>
              <Card.Header>
                <div className="header-title w-100 align-items-center flex-wrap-reverse gap-md-0 gap-3 d-flex justify-content-between">
                  <h4 className="card-title">All Plugins</h4>
                  <Form.Control
                    type="search"
                    className="w-auto ms-auto me-3 search-field"
                    id="search-data"
                    onChange={(e) => searchItems(e.target.value)}
                    placeholder="Search"
                  />
                  <Button
                    onClick={handleShowTable}
                    className="ma-btn-primary flex-grow-1 flex-md-grow-0 btn-shadow">
                    <BsPlusCircle /> Add Plugin
                  </Button>
                </div>
              </Card.Header>
              <Card.Body className="pt-2">
                <Row>
                  {searchInput.length > 1
                    ? filteredResults.map((ele) => {
                        return (
                          <Col md={6} className="mt-3 shadow-md" key={ele.id}>
                            <Card.Body
                              data-aos={ele.dir}
                              data-aos-delay={300}
                              className="border border-4 overflow-auto"
                              style={{ background: "#c3c3c359" }}>
                              <h6 className="d-flex align-items-center mb-3">
                                <span className="fs-3 bg-soft-success  me-2 rounded-circle d-flex p-1">
                                  <FaUserCircle />
                                </span>
                                {ele.user_name}
                              </h6>
                              <h6 className="d-flex align-items-center mb-3">
                                <span className="fs-3 bg-soft-danger me-2 rounded-circle d-flex p-1">
                                  <BsPhoneVibrate />
                                </span>
                                {ele.user_phone}
                              </h6>
                              <h6 className="d-flex align-items-center mb-3">
                                <span className="fs-3 bg-soft-primary me-2 rounded-circle d-flex p-1">
                                  <MdAlternateEmail />
                                </span>
                                {ele.user_email}
                              </h6>
                              <h6 className="d-flex align-items-center mb-3">
                                <span className="fs-3 bg-soft-warning me-2 rounded-circle d-flex p-1">
                                  <BiBuildings />
                                </span>
                                {ele.company_name}
                              </h6>
                              <h6 className="d-flex align-items-center mb-3">
                                <span className="fs-3 bg-soft-info me-2 rounded-circle d-flex p-1">
                                  <Si1Password />
                                </span>
                                {ele.user_password}
                              </h6>
                              <h6 className="d-flex align-items-center mb-3">
                                <span className="fs-3 bg-soft-primary me-2 rounded-circle d-flex p-1">
                                  <RiLinksLine />
                                </span>
                                {ele.website_URL}
                              </h6>

                              <Link
                                to={`/ManagePlugin/${ele.id}`}
                                className="btn btn-primary ma-btn-primary w-100 mt-3">
                                Manage Plugin
                              </Link>
                            </Card.Body>
                          </Col>
                        );
                      })
                    : partnersData.map((ele) => {
                        return (
                          <Col md={6} className="mt-3 shadow-md" key={ele.id}>
                            <Card.Body
                              data-aos={ele.dir}
                              data-aos-delay={300}
                              className="border border-4 overflow-auto"
                              style={{ background: "#c3c3c359" }}>
                              <h6 className="d-flex align-items-center mb-3">
                                <span className="fs-3 bg-soft-success  me-2 rounded-circle d-flex p-1">
                                  <FaUserCircle />
                                </span>
                                {ele.user_name}
                              </h6>
                              <h6 className="d-flex align-items-center mb-3">
                                <span className="fs-3 bg-soft-danger me-2 rounded-circle d-flex p-1">
                                  <BsPhoneVibrate />
                                </span>
                                {ele.user_phone}
                              </h6>
                              <h6 className="d-flex align-items-center mb-3">
                                <span className="fs-3 bg-soft-primary me-2 rounded-circle d-flex p-1">
                                  <MdAlternateEmail />
                                </span>
                                {ele.user_email}
                              </h6>
                              <h6 className="d-flex align-items-center mb-3">
                                <span className="fs-3 bg-soft-warning me-2 rounded-circle d-flex p-1">
                                  <BiBuildings />
                                </span>
                                {ele.company_name}
                              </h6>
                              <h6 className="d-flex align-items-center mb-3">
                                <span className="fs-3 bg-soft-info me-2 rounded-circle d-flex p-1">
                                  <Si1Password />
                                </span>
                                {ele.user_password}
                              </h6>
                              <h6 className="d-flex align-items-center mb-3">
                                <span className="fs-3 bg-soft-primary me-2 rounded-circle d-flex p-1">
                                  <RiLinksLine />
                                </span>
                                {ele.website_URL}
                              </h6>

                              <Link
                                to={`/ManagePlugin/${ele.id}`}
                                className="btn btn-primary ma-btn-primary w-100 mt-3">
                                Manage Plugin
                              </Link>
                            </Card.Body>
                          </Col>
                        );
                      })}
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
      <Modaljs open={showTable} close={handleCloseTable} title={"Add Plugin"}>
        <AddForm close={handleCloseTable} />
      </Modaljs>
    </>
  );
};

export default AllPlugins;
