import { Formik } from "formik";
import React from "react";
import { useState } from "react";
import { Button, Card, Col, Form, Row, Spinner } from "react-bootstrap";
import { BiEdit } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { selectUser, setUser } from "../../features/authSlice";
import { updatePartnerDetails } from "../../Services/adminApi";
import { addGeneralSchema } from "../../utils/validationSchemas";

const GeneralInformation = () => {
	const dispatch = useDispatch();
	const notify = (message) => {
		toast(message);
	};
	const [isLoading, setIsLoading] = useState(false);
	const { user } = useSelector(selectUser);
	const [selectedImage, setSelectedImage] = useState(null);
	const handleGeneralData = async (values, setSubmitting) => {
		setIsLoading(true);
		const bodyFormData = new FormData();
		bodyFormData.set("id", user.login_id || user.id);
		bodyFormData.set("user_name", values.user_name);
		bodyFormData.set("user_email", values.user_email);
		bodyFormData.set("user_phone", values.user_phone);
		bodyFormData.set(
			"company_logo",
			selectedImage ? selectedImage : values.company_logo
		);
		const res = await updatePartnerDetails(bodyFormData);
		if (res.status) {
			notify(res.message);
			dispatch(setUser(res.data[0]));
		} else {
			notify(res.message);
		}
		setSubmitting(false);
		setIsLoading(false);
	};

	return (
		<>
			<Row>
				<Col md={12}>
					<Card className="h-100" data-aos={"fade-up"} data-aos-delay={200}>
						<Card.Header>
							<Card.Title>Genral Informations</Card.Title>
						</Card.Header>
						<Card.Body>
							<Formik
								validationSchema={addGeneralSchema}
								enableReinitialize={true}
								initialValues={{
									user_name: user?.user_name,
									user_email: user?.user_email,
									user_phone: user?.user_phone,
									company_logo: user?.company_logo ? user?.company_logo : "",
								}}
								onSubmit={(values, { setSubmitting, resetForm }) => {
									handleGeneralData(values, setSubmitting, resetForm);
								}}
							>
								{({
									values,
									errors,
									touched,
									handleChange,
									handleBlur,
									handleSubmit,
								}) => (
									<Form
										noValidate
										onSubmit={handleSubmit}
										className="form-horizontal"
									>
										<Form.Group className="mb-3 row">
											<Form.Label className="col-md-3 align-self-center mb-0">
												Name <span className="text-danger">*</span>{" "}
											</Form.Label>
											<Col md={6}>
												<Form.Control
													onChange={handleChange}
													onBlur={handleBlur}
													name="user_name"
													value={values.user_name}
													type="text"
													placeholder="Enter Name"
												/>
												<small className="text-danger">
													{errors.user_name &&
														touched.user_name &&
														errors.user_name}
												</small>
											</Col>
										</Form.Group>
										<Form.Group className="mb-3 row">
											<Form.Label className="col-md-3 align-self-center mb-0">
												Email <span className="text-danger">*</span>{" "}
											</Form.Label>
											<Col md={6}>
												<Form.Control
													onChange={handleChange}
													onBlur={handleBlur}
													name="user_email"
													value={values.user_email}
													type="email"
													placeholder="Enter email"
												/>
												<small className="text-danger">
													{errors.user_email &&
														touched.user_email &&
														errors.user_email}
												</small>
											</Col>
										</Form.Group>
										<Form.Group className="mb-3 row">
											<Form.Label className="col-md-3 align-self-center mb-0">
												Phone No. <span className="text-danger">*</span>{" "}
											</Form.Label>
											<Col md={6}>
												<Form.Control
													onChange={handleChange}
													onBlur={handleBlur}
													name="user_phone"
													maxLength={10}
													value={values.user_phone}
													type="text"
													placeholder="Enter Phone No."
												/>
												<small className="text-danger">
													{errors.user_phone &&
														touched.user_phone &&
														errors.user_phone}
												</small>
											</Col>
										</Form.Group>
										<Form.Group className="mb-3 row">
											<Form.Label className="col-md-3 align-self-center mb-0">
												Company Logo
											</Form.Label>
											<Col md={6}>
												<div className="d-flex">
													<img
														src={
															selectedImage
																? URL.createObjectURL(selectedImage)
																: `${process.env.REACT_APP_API_URL_LIVE}/${user.company_logo}`
														}
														width={"100px"}
													/>
													<BiEdit
														className="fs-4 position-relative "
														style={{ left: "20px" }}
													/>
													<Form.Control
														onChange={(e) => {
															handleChange(e);
															setSelectedImage(e.target.files[0]);
														}}
														onBlur={handleBlur}
														className="position-relative opacity-0 "
														name="company_logo"
														type="file"
														style={{ width: "20px" }}
													/>

													<small className="text-danger">
														{errors.company_logo &&
															touched.company_logo &&
															errors.company_logo}
													</small>
												</div>
											</Col>
										</Form.Group>
										<hr />
										<Button
											variant="primary"
											type={"submit"}
											className="ma-btn-primary"
											style={{ width: "155px" }}
										>
											{isLoading ? (
												<Spinner animation="border" size="sm" variant="light" />
											) : (
												"Save Changes"
											)}
										</Button>
									</Form>
								)}
							</Formik>
						</Card.Body>
					</Card>
				</Col>
			</Row>
		</>
	);
};

export default GeneralInformation;
