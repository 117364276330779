import { Formik } from "formik";
import { useState } from "react";
import { Col, Row, Form, Button, Spinner } from "react-bootstrap";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { selectUser } from "../../features/authSlice";
import { createPluginsClients } from "../../Services/adminApi";
import { addClientSchema } from "../../utils/validationSchemas";

const AddForm = ({ close }) => {
  const notify = (message) => {
    toast(message);
  };
  const [isLoading, setIsLoading] = useState(false);
  const { user } = useSelector(selectUser);
  const handleAddData = async (values, setSubmitting) => {
    setIsLoading(true);
    const bodyFormData = new FormData();
    bodyFormData.set("partner_id", user.login_id || user.id);
    bodyFormData.set("email", values.email);
    bodyFormData.set("name", values.name);
    bodyFormData.set("email", values.email);
    bodyFormData.set("phone", values.phone);
    bodyFormData.set("password", values.password);
    bodyFormData.set("confirmPassword", values.confirmPassword);
    bodyFormData.set("companyName", values.companyName);
    bodyFormData.set("companyAddress", values.companyAddress);
    bodyFormData.set("companyPhone", values.companyPhone);
    bodyFormData.set("domainName", values.domainName);
    bodyFormData.set("companyLogo", values.companyLogo);
    bodyFormData.set("facebookLink", values.facebookLink);
    bodyFormData.set("twitterLink", values.twitterLink);
    bodyFormData.set("instgramLink", values.instgramLink);
    bodyFormData.set("linkedin", values.linkedin);
    bodyFormData.set("googleplus", values.googleplus);
    bodyFormData.set("companyProfile", values.companyProfile);
    const res = await createPluginsClients(bodyFormData);
    if (res.status) {
      notify(res.message);
      close();
    } else {
      notify(res.message);
    }
    setSubmitting(false);
    setIsLoading(true);
  };

  return (
    <>
      <Formik
        validationSchema={addClientSchema}
        initialValues={{
          name: "",
          email: "",
          phone: "",
          password: "",
          confirmPassword: "",
          companyName: "",
          companyAddress: "",
          companyPhone: "",
          domainName: "",
          companyLogo: "",
          facebookLink: "",
          twitterLink: "",
          instgramLink: "",
          linkedin: "",
          googleplus: "",
          companyProfile: "",
        }}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          handleAddData(values, setSubmitting, resetForm);
        }}>
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
        }) => (
          <>
            <form noValidate onSubmit={handleSubmit}>
              <Row className="mb-2 row-cols-1 row-cols-lg-3">
                <Col>
                  <Form.Group className="mb-3">
                    <Form.Label>
                      Name <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control
                      onChange={handleChange}
                      onBlur={handleBlur}
                      name="name"
                      value={values.name}
                      type="text"
                      placeholder="Enter name"
                    />
                    <small className="text-danger">
                      {errors.name && touched.name && errors.name}
                    </small>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mb-3">
                    <Form.Label>
                      Email address <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control
                      onChange={handleChange}
                      onBlur={handleBlur}
                      name="email"
                      value={values.email}
                      type="email"
                      placeholder="Enter email"
                    />
                    <small className="text-danger">
                      {errors.email && touched.email && errors.email}
                    </small>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mb-3">
                    <Form.Label>
                      Phone <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control
                      onChange={handleChange}
                      onBlur={handleBlur}
                      name="phone"
                      maxLength={"10"}
                      value={values.phone}
                      type="text"
                      placeholder="Enter phone"
                    />
                    <small className="text-danger">
                      {errors.phone && touched.phone && errors.phone}
                    </small>
                  </Form.Group>
                </Col>
              </Row>
              <Row className="mb-2 row-cols-1 row-cols-lg-3">
                <Col>
                  <Form.Group className="mb-3">
                    <Form.Label>
                      Password <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control
                      onChange={handleChange}
                      onBlur={handleBlur}
                      name="password"
                      value={values.password}
                      type="text"
                      placeholder="Enter password"
                    />
                    <small className="text-danger">
                      {errors.password && touched.password && errors.password}
                    </small>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mb-3">
                    <Form.Label>
                      Confirm Password <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control
                      onChange={handleChange}
                      onBlur={handleBlur}
                      name="confirmPassword"
                      value={values.confirmPassword}
                      type="text"
                      placeholder="Enter confirm password"
                    />
                    <small className="text-danger">
                      {errors.confirmPassword &&
                        touched.confirmPassword &&
                        errors.confirmPassword}
                    </small>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mb-3">
                    <Form.Label>
                      Company Name <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control
                      onChange={handleChange}
                      onBlur={handleBlur}
                      name="companyName"
                      value={values.companyName}
                      type="text"
                      placeholder="Enter company name"
                    />
                    <small className="text-danger">
                      {errors.companyName &&
                        touched.companyName &&
                        errors.companyName}
                    </small>
                  </Form.Group>
                </Col>
              </Row>
              <Row className="mb-2 row-cols-1 row-cols-lg-3">
                <Col>
                  <Form.Group className="mb-3">
                    <Form.Label>Company Profile</Form.Label>
                    <Form.Control
                      onChange={handleChange}
                      onBlur={handleBlur}
                      name="companyProfile"
                      value={values.companyProfile}
                      type="text"
                      placeholder="Enter company profile"
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mb-3">
                    <Form.Label>
                      Company Address <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control
                      onChange={handleChange}
                      onBlur={handleBlur}
                      name="companyAddress"
                      value={values.companyAddress}
                      type="text"
                      placeholder="Enter company address"
                    />
                    <small className="text-danger">
                      {errors.companyAddress &&
                        touched.companyAddress &&
                        errors.companyAddress}
                    </small>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mb-3">
                    <Form.Label>
                      Company Phone Number{" "}
                      <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control
                      onChange={handleChange}
                      onBlur={handleBlur}
                      name="companyPhone"
                      maxLength={"10"}
                      value={values.companyPhone}
                      type="text"
                      placeholder="Enter company phone no."
                    />
                    <small className="text-danger">
                      {errors.companyPhone &&
                        touched.companyPhone &&
                        errors.companyPhone}
                    </small>
                  </Form.Group>
                </Col>
              </Row>
              <Row className="mb-2 row-cols-1 row-cols-lg-3">
                <Col>
                  <Form.Group className="mb-3">
                    <Form.Label>
                      Domain Name <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control
                      onChange={handleChange}
                      onBlur={handleBlur}
                      name="domainName"
                      value={values.domainName}
                      type="text"
                      placeholder="Enter domain name"
                    />
                    <small className="text-danger">
                      {errors.domainName &&
                        touched.domainName &&
                        errors.domainName}
                    </small>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mb-3">
                    <Form.Label>Facebook Link</Form.Label>
                    <Form.Control
                      onChange={handleChange}
                      onBlur={handleBlur}
                      name="facebookLink"
                      value={values.facebookLink}
                      type="text"
                      placeholder="Enter facebook link"
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mb-3">
                    <Form.Label>Instagram Link</Form.Label>
                    <Form.Control
                      onChange={handleChange}
                      onBlur={handleBlur}
                      name="instgramLink"
                      value={values.instgramLink}
                      type="text"
                      placeholder="Enter instagram link"
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row className="mb-2 row-cols-1 row-cols-lg-3">
                <Col>
                  <Form.Group className="mb-3">
                    <Form.Label>Twitter Link</Form.Label>
                    <Form.Control
                      onChange={handleChange}
                      onBlur={handleBlur}
                      name="twitterLink"
                      value={values.twitterLink}
                      type="text"
                      placeholder="Enter twitter link"
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mb-3">
                    <Form.Label>Google Plus</Form.Label>
                    <Form.Control
                      onChange={handleChange}
                      onBlur={handleBlur}
                      name="googleplus"
                      value={values.googleplus}
                      type="text"
                      placeholder="Enter google plus"
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mb-3">
                    <Form.Label>Linked In</Form.Label>
                    <Form.Control
                      onChange={handleChange}
                      onBlur={handleBlur}
                      name="linkedin"
                      value={values.linkedin}
                      type="text"
                      placeholder="Enter linkedIn link"
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row className="row-cols-1">
                <Col>
                  <Form.Group className="mb-3">
                    <Form.Label>
                      Company Logo <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control
                      // onChange={}
                      onBlur={handleBlur}
                      // onChange={handleChange}
                      onChange={(e) => {
                        // handleChange();
                        // setShowLogo(e.target.files[0]);
                        setFieldValue("companyLogo", e.target.files[0]);
                      }}
                      name="companyLogo"
                      type="file"
                    />
                    <small className="text-danger">
                      {errors.companyLogo &&
                        touched.companyLogo &&
                        errors.companyLogo}
                    </small>
                  </Form.Group>
                </Col>
                <Col className="mt-4">
                  <div className="text-end">
                    <Button variant="secondary" onClick={close}>
                      Close
                    </Button>{" "}
                    {"  "}
                    <Button isloading={isSubmitting} type="submit">
                      {isLoading ? (
                        <>
                          <Spinner
                            animation="border"
                            variant="light"
                            size="sm"
                          />
                        </>
                      ) : (
                        "Save"
                      )}
                    </Button>
                  </div>
                </Col>
              </Row>
            </form>
          </>
        )}
      </Formik>
    </>
  );
};

export default AddForm;
