import axios from "axios";

const customApi = axios.create({
  baseURL: process.env.REACT_APP_API_URL_LIVE,
  headers: {
    email: process.env.REACT_APP_API_EMAIL,
    password: process.env.REACT_APP_API_PASSWORD,
  },
});

customApi.interceptors.request.use(function (config) {
  let token = localStorage.getItem("token");
  config.headers["Authorization"] = `Bearer ${token}`;
  return config;
});

export const getAllPartnersClients = async (values) => {
  try {
    const { data } = await customApi.post("/api/partnerallclient", values);
    return data;
  } catch (error) {
    return error.response.data;
  }
};
export const getOnePartnerClient = async (values) => {
  try {
    const { data } = await customApi.post("/api/partneroneclient", values);
    return data;
  } catch (error) {
    return error.response.data;
  }
};
export const updateOneClient = async (values) => {
  try {
    const { data } = await customApi.post(
      "/api/updatepartneroneclient",
      values
    );
    return data;
  } catch (error) {
    return error.response.data;
  }
};
export const createPluginsClients = async (values) => {
  try {
    const { data } = await customApi.post(`/api/createplugin`, values);
    return data;
  } catch (error) {
    return error.response.data;
  }
};
export const getScriptData = async (values) => {
  try {
    const { data } = await customApi.post(`/api/getpluginscript`, values);
    return data;
  } catch (error) {
    return error.response.data;
  }
};
export const UpdatePartnerPassword = async (udata) => {
  try {
    const { data } = await customApi.post("/api/updatepartnerpassword", udata);
    return data;
  } catch (error) {
    return error.response.data;
  }
};
export const selectClientPayment = async (values) => {
  try {
    const { data } = await customApi.post("/api/setpluginexpiry", values);
    return data;
  } catch (error) {
    return error.response.data;
  }
};
export const selectPartnerPayment = async (values) => {
  try {
    const { data } = await customApi.post("/api/setpartnerexpiry", values);
    return data;
  } catch (error) {
    return error.response.data;
  }
};
export const getSettingsData = async () => {
  try {
    const { data } = await customApi.get(`/api/admindetails`);
    return data;
  } catch (error) {
    return error.response.data;
  }
};
export const changePassword = async (udata) => {
  try {
    const { data } = await customApi.post("/api/updatepluginpassword", udata);
    return data;
  } catch (error) {
    return error.response.data;
  }
};
export const updatePartnerDetails = async (values) => {
  try {
    const { data } = await customApi.post("/api/updatepartnerdetails", values);
    return data;
  } catch (error) {
    return error.response.data;
  }
};
