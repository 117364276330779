import { Formik } from "formik";
import React from "react";
import { useState } from "react";
import { Button, Card, Col, Form, Row, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import { toast } from "react-toastify";
import { logout, selectUser } from "../../features/authSlice";
import { UpdatePartnerPassword } from "../../Services/adminApi";
import { changePasswordSchema } from "../../utils/validationSchemas";

const ChangePassword = () => {
  const [isLaoding, setIsLaoding] = useState(false);
  const { user } = useSelector(selectUser);
  const dispatch = useDispatch();
  const notify = (message) => {
    toast(message);
  };
  const handleChangePassword = async (values) => {
    setIsLaoding(true);
    const passwordData = {
      id: user.login_id || user.id,
      password: values.new_password,
    };
    const res = await UpdatePartnerPassword(passwordData);
    if (res.status) {
      notify(res.message);
      dispatch(logout());
    } else {
      notify(res.message);
    }
    setIsLaoding(false);
  };
  return (
    <Row>
      <Col md={12}>
        <Card className="h-100" data-aos={"fade-up"} data-aos-delay={200}>
          <Card.Header>
            <Card.Title>Change Password Informations</Card.Title>
          </Card.Header>
          <Card.Body>
            <Formik
              validationSchema={changePasswordSchema}
              initialValues={{
                new_password: "",
                re_type_password: "",
              }}
              onSubmit={(values, { setSubmitting, resetForm }) => {
                handleChangePassword(values, setSubmitting, resetForm);
              }}>
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
              }) => (
                <Form
                  noValidate
                  onSubmit={handleSubmit}
                  className="form-horizontal">
                  <Form.Group className="mb-3 row">
                    <Form.Label className="col-md-3 align-self-center mb-0">
                      New Password <span className="text-danger">*</span>{" "}
                    </Form.Label>
                    <Col md={6}>
                      <Form.Control
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name="new_password"
                        value={values.new_password}
                        type="password"
                        placeholder="Enter New Password"
                      />
                      <small className="text-danger">
                        {errors.new_password &&
                          touched.new_password &&
                          errors.new_password}
                      </small>
                    </Col>
                  </Form.Group>
                  <Form.Group className="mb-3 row">
                    <Form.Label className="col-md-3 align-self-center mb-0">
                      Re-Type New Password{" "}
                      <span className="text-danger">*</span>{" "}
                    </Form.Label>
                    <Col md={6}>
                      <Form.Control
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name="re_type_password"
                        value={values.re_type_password}
                        type="password"
                        placeholder="Enter Retype New Password"
                      />
                      <small className="text-danger">
                        {errors.re_type_password &&
                          touched.re_type_password &&
                          errors.re_type_password}
                      </small>
                    </Col>
                  </Form.Group>

                  <hr />
                  <Button
                    variant="primary"
                    type="submit"
                    className="ma-btn-primary"
                    style={{ width: "155px" }}>
                    {isLaoding ? (
                      <Spinner animation="border" size="sm" variant="light" />
                    ) : (
                      "Save Changes"
                    )}
                  </Button>
                </Form>
              )}
            </Formik>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};

export default ChangePassword;
